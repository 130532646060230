<div class="wrap">
    <mat-card appearance="outlined" class="helpCard">
        <a target="_blank" rel="noopener noreferrer"
            href="https://totalworkplace.sharepoint.com/:f:/r/sites/Deploy-DigitalProductsAppsSupport/Shared%20Documents/R%26C/RTPCA/User%20Guide?csf=1&web=1&e=e4stD1">
            <mat-card-content class="helpCardContent">
                <span class="material-icons iconSize">
                    menu_book
                </span>
                <div class="navbar-text text-uppercase ms-2">{{'help.userGuide' | translate}}</div>
            </mat-card-content>
        </a>
    </mat-card>
    <mat-card appearance="outlined" class="helpCard">
        <a target="_blank" rel="noopener noreferrer"
            href="https://totalworkplace.sharepoint.com/:f:/r/sites/Deploy-DigitalProductsAppsSupport/Shared%20Documents/R%26C/RTPCA/Contacts?csf=1&web=1&e=loqmFa">
            <mat-card-content class="helpCardContent">
                <span class="material-icons iconSize">
                    contact_page
                </span>
                <div class="navbar-text text-uppercase ms-2">{{'help.contacts' | translate}}</div>
            </mat-card-content>
        </a>
    </mat-card>
    <mat-card appearance="outlined" class="helpCard">
        <a target="_blank" rel="noopener noreferrer"
            href="https://totalworkplace.sharepoint.com/:f:/r/sites/Deploy-DigitalProductsAppsSupport/Shared%20Documents/R%26C/RTPCA/One%20Pager?csf=1&web=1&e=X494lh">
            <mat-card-content class="helpCardContent">
                <span class="material-icons iconSize">
                    add_comment
                </span>
                <div class="navbar-text text-uppercase ms-2">{{'help.onePager' | translate}}</div>
            </mat-card-content>
        </a>
    </mat-card>
    <mat-card appearance="outlined" class="helpCard">
        <a target="_blank" rel="noopener noreferrer"
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=sJGeMh_i-0igcUVnF-zCjtP2Plrviw9PgEmEYhOK_PhUREJaQlozOUVKR0dERlJCS09PRlNGUDVUSC4u">
            <mat-card-content class="helpCardContent">
                <span class="material-icons iconSize">
                    add_reaction
                </span>
                <div class="navbar-text text-uppercase ms-2">{{'help.userFeedback' | translate}}</div>
            </mat-card-content>
        </a>
    </mat-card>
</div>