import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'rtpca-help',
  templateUrl: './help.component.html',
  standalone: true,
  imports: [TranslateModule, MatCardModule],
  styleUrl: './help.component.css',
})
export class HelpComponent {
}
