<nav class="navbar navbar-expand-sm flex-column">
  <div class="container-fluid d-flex w-100 header-with-tabs-for-desktop">
    <!-- TotalEnergies Logo -->
    <a class="navbar-brand text-primary" href="#">
      <img src="assets/images/te_logo.png" alt="Logo TotalEnergies" />
    </a>
    <!-- RTPCA Logo -->
    <a class="navbar-brand text-primary" href="#">
      <img src="assets/images/rtpca_logo.png" style="height: 48px;width: 48px;" alt="RTPCA TotalEnergies" />
      <span class="navbar-app-name d-flex align-items-center" style="justify-content: center;">{{
        'appName' | translate
        }}</span>
    </a>
    <div class="w-100">
      <div class="container-fluid d-flex pt-2 pe-0">
        <!-- Navbar content -->
        <ul class="navbar-nav ms-auto d-flex flex-row">

          <li class="nav-item ms-md-4 d-flex">
            <span class="navbar-text navbar-app-name d-none d-md-block">
              <rtpca-dbt-runner></rtpca-dbt-runner>
            </span>
          </li>

          <li class="nav-item ms-md-4 d-flex">
            <span class="navbar-text navbar-app-name d-none d-md-block">{{
              (connectedUser | async)?.roles ?? [] | extractRole
            }}</span>
          </li>

          <li class="nav-item ms-md-4">
            <div class="dropdown">
              <span class="navbar-text text-uppercase">{{
                (connectedUser | async)?.username
              }}</span>
              <button
                class="btn btn-outline-primary btn-circle"
                id="profile"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="material-icons-outlined">account_circle</span>
              </button>
              <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="profile"
              >
                <li>
                  <a class="dropdown-item" href="#" (click)="logout()">{{
                    'common.button.logout' | translate
                  }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a (click)="help()">
              <button class="btn btn-outline-primary btn-circle">
                <span class="material-icons-outlined">
                  contact_support
                </span>
              </button>
            </a>
          </li>
          <li class="nav-item d-none d-xl-block">
            <div class="dropdown">
              <button
                class="btn dropdown-toggle"
                type="button"
                id="lang"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ currentLang | uppercase }}
              </button>
              <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="lang"
              >
                <li>
                  <a
                    (click)="switchLanguage('en')"
                    class="dropdown-item"
                    href="#"
                    >{{ 'language.english' | translate }}</a
                  >
                </li>
                <li>
                  <a
                    (click)="switchLanguage('fr')"
                    class="dropdown-item"
                    href="#"
                    >{{ 'language.french' | translate }}</a
                  >
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <hr class="separator" />
      <ng-container *ngIf="(connectedUser | async) as user">
        <ng-container *ngIf="user?.roles && user.roles.length > 0">
          <ng-container *ngIf="extractRole(user.roles)">
            <div class="container-fluid">
              <ul class="nav nav-tabs">
                @for( tab of availableTabs; track tab){
                <li *ngIf="showNavbar">
                  <a class="nav-link" aria-current="page" [routerLink]="tab.route"
                    [ngClass]="{ active: (tabName$ | async) === tab.label }" (click)="onChangeTab(tab.label)">{{
                    'navbar.TAB_LABELS.' + tab.label | translate }}</a>
                </li>
                }
                <li *ngIf="!showNavbar">
                  <a class="nav-link active" aria-current="page"
                    >{{
                    'navbar.TAB_LABELS.' + 'help' | translate }}</a>
                </li>
              </ul>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</nav>
