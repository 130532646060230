import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EndSessionRequest,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { EventError } from '@azure/msal-browser/dist/event/EventMessage';
import { ConnectedUser } from '../types/connected-user.interface';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public connectedUser: BehaviorSubject<ConnectedUser | null> =
    new BehaviorSubject<ConnectedUser | null>(null);
  appInsights: ApplicationInsights;
  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router
  ) {
    // Init Service
    this._activeFirstAccountFromSessionStorage();
    this._loadActiveAccount();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightKey,
        enableAutoRouteTracking: true 
      }
    });
    this.appInsights.loadAppInsights();

    this.msalBroadcastService.msalSubject$.subscribe({
      next: (event: EventMessage) => {
        switch (event.eventType) {
          case EventType.LOGIN_SUCCESS:
          case EventType.ACQUIRE_TOKEN_SUCCESS: {
            const payload = event.payload as AuthenticationResult;
            this.appInsights.setAuthenticatedUserContext(payload.account.username, payload.account.localAccountId, true);
            this._onLogin(payload.account);
            break;
          }

          case EventType.LOGOUT_SUCCESS: {
            this.logout();
            break;
          }

          case EventType.LOGIN_FAILURE:
          case EventType.ACQUIRE_TOKEN_FAILURE: {
            this._onLoginFailure(event.error);
            break;
          }

          default: {
            break;
          }
        }
      },
    });
  }

  public logout(logoutRequest?: EndSessionRequest) {
    this.msalService.logout(logoutRequest);
  }

  private _onLogin(account: AccountInfo | null) {
    if (!account) {
      return;
    }

    this.msalService.instance.setActiveAccount(account);
    this._loadActiveAccount();
  }

  private _onLoginFailure(error: EventError) {
    this.connectedUser.next(null);
    this.router.navigate(['/unauthorized']);
  }

  private _accountInfoToConnectedUser(
    accountInfo: AccountInfo | null
  ): null | ConnectedUser {
    if (!accountInfo) return null;

    return {
      username: accountInfo.name,
      roles: accountInfo.idTokenClaims?.roles,
    } as ConnectedUser;
  }

  private _loadActiveAccount() {
    if (!this.msalService.instance.getActiveAccount()) {
      return;
    }
    const activeAccount = this._accountInfoToConnectedUser(
      this.msalService.instance.getActiveAccount()
    );
    this.connectedUser.next(activeAccount);
  }

  private _activeFirstAccountFromSessionStorage() {
    const account = this.msalService.instance.getAllAccounts();
    if (account && account.length > 0) {
      this.msalService.instance.setActiveAccount(account[0]);
    }
  }
}
