import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RoleGuard } from './core/security/msal.config';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { HelpComponent } from './homepage/help/help.component';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: '/mapping',
    pathMatch: 'full',
  },
  {
    path: 'mapping',
    loadChildren: () =>
      import('./features/user-mapping/user-mapping.routes').then(
        (m) => m.userMappingRoutes
      ),
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'program-config',
    canActivate: [MsalGuard, RoleGuard],
    loadChildren: () =>
      import('./features/program-config/program-config.routes').then(
        (m) => m.programConfigRoutes
      ),
  },
  {
    path: 'data-calendar',
    loadChildren: () =>
      import('./features/data-calendar/data-calendar.routes').then(
        (m) => m.dataCalendarRoutes
      ),
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'budget',
    loadChildren: () =>
      import('./features/budgets/budget.routes').then((m) => m.budgetRoutes),
    canActivate: [MsalGuard, RoleGuard],
  },
  {
    path: 'unauthorized',
    component: PageNotFoundComponent,
  },
  {
    path: 'help',
    component: HelpComponent,
    canActivate: [MsalGuard, RoleGuard],
  }
];
